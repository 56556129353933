<template>
  <table-view
    :total="total"
    v-loading="loading"
    :query-info.sync="queryInfo"
    @reload-table="renderTable">
    <template #header>
      <view-search-form
        keyword-placeholder="部门名称"
        :query-info.sync="queryInfo"
        :tool-list="['keyword']"
        :show-clear-btn="false"
        style="display: inline-block;"
        @on-search="renderTable(1)"
      />
      <div class="header-button fr">
        <upload-excel :permit-key="['basic:department:edit']"
                      :loading.sync="loading"
                      :api="$http.importExcelHttp.importDepartment"
                      @on-download="$http.exportExcelHttp.exportDepartmentTemplate()"
                      @on-success="renderTable(1)"/>
        <el-button
          v-permission="['basic:department:edit']"
          type="primary"
          size="small"
          @click="showDialog()"
        >添加部门
        </el-button>
      </div>
    </template>
    <!-- body -->
    <el-table :data="tableData" style="width: 100%" border stripe>
      <el-table-column type="index" label="ID" width="50" />
      <el-table-column prop="deptName" label="部门名称" width="200" />
      <el-table-column prop="remarks" label="备注" />
      <el-table-column label="操作" width="150" fixed="right">
        <template v-slot="{ row }">
          <el-button v-permission="['basic:department:edit']"
                     type="primary"
                     size="mini"
                     @click="showDialog( row.id)"
          >编辑
          </el-button>
          <el-button v-permission="['basic:department:del']"
                     size="mini"
                     type="danger"
                     @click="del(deleteDepartment, row.id,`部门-${row.deptName}`)"
          >删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <!--  添加/编辑弹窗  -->
    <department-dialog ref="dialogRef" :visible.sync="visibleDialog" v-if="visibleDialog" @on-close="renderTable" />
  </table-view>
</template>

<script>
import { getDepartmentList, deleteDepartment } from '@/api/department'
import tableView from '@/vue/mixins/table-view'
import DepartmentDialog from '@/views/pages/baseInfo/department/dialog/departmentDialog.vue'

export default {
  name: 'department',
  components: {
    DepartmentDialog
  },
  mixins: [tableView],
  data () {
    return {
      queryInfo: {
        campusId: null
      }
    }
  },
  mounted () {
    this.renderTable(1)
  },
  methods: {
    deleteDepartment, // 删除api
    // 获取部门列表
    async renderTable (pageNum) {
      await this.getTableData(getDepartmentList, pageNum)
    }
  }
}
</script>
