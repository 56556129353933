<template>
  <el-dialog
    class='grade-dialog'
    :title='`${preTitle}部门`'
    :visible.sync='show'
    :close-on-click-modal='false'
    width='540px'
    :before-close='cancel'
  >
    <el-form ref='form' :model='formData' :rules='rules' label-width='100px'>
      <el-form-item label='部门名称' prop='deptName'>
        <el-input v-model.trim='formData.deptName' />
      </el-form-item>
      <el-form-item label='备注' prop='remarks'>
        <el-input v-model.trim='formData.remarks' />
      </el-form-item>
    </el-form>
    <template #footer>
      <cancel-popover ref="cancelRef" :disabled.sync="loadData.loading" :update-flag="updateFlag"/>
      <el-button type='primary' @click='save("部门",addDepartment)'>确 定</el-button>
    </template>
  </el-dialog>
</template>

<script>
import { addDepartment, getDepartmentById } from '@/api/department'
import dialog from '@/vue/mixins/dialog'

export default {
  name: 'departmentDialog',
  mixins: [dialog],
  data () {
    return {
      formData: {
        id: '',
        deptName: '', // 部门名称
        managerUserId: '', // 管理员
        remarks: ''
      },
      campusList: [],
      rules: {
        deptName: [{ required: true, message: '请输入部门名称', trigger: 'blur' },
          this.$store.state.commonValidate.limit30WordsObj
        ]
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      if (this.id) {
        this.getDataById(this.id, getDepartmentById)
      } else {
        this.setInitData()
      }
    })
  },
  methods: {
    addDepartment // 保存api
  }
}
</script>
